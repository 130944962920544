.-form-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.-form-item {
  /* Auto layout */
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  margin-bottom: 10px;
  // all child not except those with class iti

  & *:not(.iti, burddy-monorepo-input-tel *) {
    width: 100%;
  }
  & burddy-monorepo-input-tel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  & .error {
    background-color: transparent;
    color: var(--error);
  }

  label {
    /* Paragraph/Small/Medium */
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    /* identical to box height, or 143% */
    display: flex;
    align-items: center;

    /* Neutral/900 */
    color: #171717;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    & * {
      display: inline;
      background-color: transparent;
    }
  }
}
