// Variables
@import 'variables/_colors.scss';
@import 'variables/_typography';
@import 'variables/_spaces';

// Placeholders
@import 'placeholder/_positions';

// Mixins
@import 'mixins/_colors';
// Classes
@import 'class/_containers';
@import 'class/_elements';
@import 'class/_colors';
@import 'class/form';

// Components
@import 'components/_button';
@import 'components/_input';
@import 'components/_select';
@import 'components/_textarea';
html,
body {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #edfafa;
}

// Main element
body {
  font-family: $f-body;
}

// Container elements

// Structure elements
hr {
  width: 80%;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
  border-color: $light-grey2;
}

a {
  color: $primary;
}
