button {
  @extend .clickable;
  @extend .item;
  @extend .center-text;
  margin: $input-minimum-margin;
  margin: $input-minimum-margin;
  background-color: $primary;
  border-color: $primary;
  border-radius: 8px;
  border-style: solid;
  color: white;
  padding: 12px;
  box-shadow: 0px 0px 0px $primary;
  @extend %flex-center;
  justify-content: center;
  align-items: center;
  img {
    margin-right: $input-minimum-margin;
    width: 1.2rem;
  }
}
button.large {
  padding: 18px;
  img {
    width: 1.5rem;
  }
}
button.small {
  padding: 2px;
  img {
    width: 0.7rem;
  }
}
